<template>
  <div>
    <v-form
      v-model="valid"
      @submit.prevent="submitHandler"
      ref="contactInfoForm"
    >
      <div class="d-flex align-center justify-space-between">
        <span
          class="d-block font-24 font-700"
          :class="isMobile ? 'font-18' : 'font-24 '"
          >{{ $t("forms.editContactInfo") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="$t('forms.save')"
          :closeTitle="$t('forms.discard')"
          @close="$emit('close')"
        ></dialog-btns>
      </div>
      <!-- add new -->
      <v-row class="my-0 py-1">
        <!-- Contact type -->
        <v-col cols="12">
          <base-auto-complete
            :items="contactTypes"
            v-model="obj.contact_type_id"
            :placeholder="$t('forms.contactType')"
            :title="$t('forms.contactType')"
          ></base-auto-complete>
        </v-col>

        <!-- CV -->
        <v-col cols="12">
          <base-file
            @input="handleFile($event, 'cv')"
            v-model="obj.cv"
            :isRequired="true"
            :isClear="true"
            :placeholder="$t('forms.cv')"
            :title="$t('forms.cv')"
            :rules="[(v) => !!v || '']"
          ></base-file>
          <base-info
            :title="''"
            :value="
              info.cv ? $t('dashboard.uploaded') : $t('dashboard.notUploaded')
            "
            :isUploaded="info.cv ? true : false"
            :isFile="true"
            @click="openLink(info.cv)"
          ></base-info>
        </v-col>
        <!-- Degree file * -->
        <v-col cols="12">
          <base-file
            @input="handleFile($event, 'degree_file')"
            v-model="obj.degree_file"
            :isRequired="true"
            :isClear="true"
            :title="$t('forms.degreeFile')"
            :placeholder="$t('forms.degreeFile')"
            :rules="[(v) => !!v || '']"
          ></base-file>
          <base-info
            :title="''"
            :value="
              info.degree_file
                ? $t('dashboard.uploaded')
                : $t('dashboard.notUploaded')
            "
            :isUploaded="info.degree_file ? true : false"
            :isFile="true"
            @click="openLink(info.degree_file)"
          ></base-info>
        </v-col>

        <!-- Portfolio -->
        <v-col cols="12">
          <base-file
            @input="handleFile($event, 'portfolio')"
            v-model="obj.portfolio"
            :isRequired="false"
            :isClear="true"
            :title="$t('forms.portfolio')"
            :placeholder="$t('forms.portfolio')"
            :multiple="true"
            :rules="[]"
          ></base-file>

          <!-- portfolio -->
          <div
            v-for="(porfolio, i) in info.portfolio"
            :key="i"
            lg="2"
            md="3"
            cols="12"
            :class="isMobile ? 'py-1' : ''"
          >
            <base-info
              :title="''"
              :value="
                info.portfolio
                  ? $t('dashboard.uploaded')
                  : $t('dashboard.notUploaded')
              "
              :isUploaded="info.portfolio ? true : false"
              :isFile="true"
              @click="openLink(info.portfolio)"
            ></base-info>
          </div>
        </v-col>
      </v-row>
      <dialog-btns
        v-if="!isMobile"
        :btnTitle="
          completeProfile ? $t('forms.saveContinue') : $t('forms.save')
        "
        :closeTitle="completeProfile ? '' : $t('forms.discard')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import BaseFile from "../BaseFile.vue";
import BaseInfo from "../BaseInfo.vue";
import DialogBtns from "./DialogBtns.vue";
import BaseAutoComplete from "../BaseAutoComplete.vue";

export default {
  props: {
    completeProfile: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: { DialogBtns, BaseAutoComplete, BaseFile, BaseInfo },
  data: () => ({
    contactTypes: [],
    obj: { portfolio: [] },
  }),
  methods: {
    handleFile(file, type) {
      this.obj[type] = '';
      if (file.size > 2049892) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.fileSize"),
          color: "error",
        });
        return;
      }
      this.obj[type] = file;
    },
    async submitHandler() {
      let valid = this.$refs.contactInfoForm.validate();
      setTimeout(() => {
        document
          .querySelector(".v-input.error--text:first-of-type")
          .scrollIntoView({
            behavior: "smooth",
          });
      }, 100);
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }

      let formData = new FormData();
      formData.append("contact_type_id", this.obj.contact_type_id);
      formData.append("cv", this.obj.cv);
      formData.append("degree_file", this.obj.degree_file);
      let portfolioLength = 0;
      this.obj.portfolio.forEach((file, idx) => {
        if (typeof file != "string") {
          formData.append(`portfolio[${idx}]`, file);
          portfolioLength += 1;
        }
      });
      if (portfolioLength == 0) {
        formData.append(`portfolio[0]`, "demo");
      }
      let { data } = await this.$axios.post("contact/info/update", formData, {
        headers: {
          Accept: "application/json",
        },
      });

      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        if (this.info) {
          this.$emit("nextHandler");
          return;
        }
        this.$emit("updateData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async getContactTypes() {
      let { data } = await this.$axios.get("contact/type");
      if (data) {
        this.contactTypes = data.data;
      }
    },
    openLink(link) {
      window.open(link);
    },
  },
  async created() {
    await this.getContactTypes();
    if (this.info) {
      this.obj = { ...this.info };
      this.obj.contact_type_id = this.info.contact_type;
    } else {
      this.info = {};
      this.obj = {};
    }
  },
};
</script>

<style></style>
