<template>
  <div
    :class="isMobile ? 'd-flex align-center justify-space-between w-100' : ''"
  >
    <span
      class="d-block font-16"
      :class="isMobile ? 'darktext--text' : 'whitegreytext--text'"
      >{{ title }}</span
    >
    <span
      v-if="!isFile"
      class="d-block font-18 mt-1"
      :class="isMobile ? 'whitegreytext--text' : 'darktext--text'"
      >{{ value }}</span
    >
    <span
      v-if="isFile"
      class="d-block font-18 mt-1 font-700 cursor-pointer"
      :class="isUploaded ? 'darkprimary--text' : 'error--text'"
      @click="$emit('click')"
      >{{ value }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    value: { type: [Number, String] },
    isFile: { type: Boolean, default: false },
    isUploaded: { type: Boolean, default: false },
  },
};
</script>

<style></style>
